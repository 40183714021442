import { UserInfoContainer } from "../Modules/userInfo/userInfoContainer";
import { WelcomeScreenContainer } from "../Modules/welcomeScreen/welcomeScreenContainer";

import bg1 from "../assets/images/theme-bg-gray.png";
import bg2 from "../assets/images/theme-bg-woods.png";
import bg3 from "../assets/images/theme-bg-pink-clouds.png";

import thumbBg1 from "../assets/images/theme-bg-gray-small.jpeg";
import thumbBg2 from "../assets/images/theme-bg-woods-small.jpeg";
import thumbBg3 from "../assets/images/theme-bg-pink-clouds-small.jpeg";

//Background title constants
import curtain_up_1 from "../assets/images/theme.png";
import curtain_up_2 from "../assets/images/theme.png";
import curtain_up_3 from "../assets/images/theme.png";
import { ReleaseComponent } from "../Modules/sharedComponents/releaseComponent";
import { TermsComponent } from "../Modules/sharedComponents/termsComponent";
import { Term } from "../Modules/sharedComponents/term";
import Confirmation from "../Modules/thanksScreen";

//api constants
export const REMOVEBG_API_PATH = `https://api.remove.bg/v1.0/removebg`;
export const USER_INFO_API_PATH = "njdevils/userInfo";
export const UPLOAD_FILE_API_PATH = "/api/v1/file/upload";
export const PRIVACY_POLICY_URL = "https://www.prudential.com/links/privacy-center"
//Grid constant
export const GRID_SIZE = 420;

export const FIRST = 1;
export const SECOND = 2;
export const THIRD = 3;

//background theme constants
export const CURTAIN_UP_1 = curtain_up_1;
export const CURTAIN_UP_2 = curtain_up_2;
export const CURTAIN_UP_3 = curtain_up_3;

//Remove bg api key
export const REMOVEBG_API_KEY = process.env.REACT_APP_REMOVEBG_API_KEY;

//Photo filter constants
export const BACKGROUND = "Background";
export const PHOTO = "Photo";
export const TEXT = "Text";

//constants for image-filter
export const BLACKANDWHITE = "black-white-filter";
export const VIBRANT = "vibrant-filter";
export const NONE = "none-filter";

//Routes constants
export const APP_BASE_ROUTE = "/";
export const CONFIRM_SCREEN = "/confirmation";
export const USERINFO = "/";
export const PRIVACY_POLICY = "/privacy-policy";
export const RELEASE = "/release";
export const TERMS = "/terms";
export const TERMS_PAGE = "/terms-page";

export const ROUTES = [

  {
    path: USERINFO,
    element: <UserInfoContainer />,
  },
  {
    path: CONFIRM_SCREEN,
    element: <Confirmation />,
  },
  {
    path: RELEASE,
    element: <ReleaseComponent />,
  },
  {
    path: TERMS,
    element: <TermsComponent />,
  },
  {
    path: TERMS_PAGE,
    element: <Term />,
  },
];

export const MAX_CHAR_LENGTH = 25;
