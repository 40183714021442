import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "./../../assets/images/header.png";
import footer from "./../../assets/images/footer.png";
import "./style.scss";

export default function Confirmation() {
  const navigate = useNavigate();
  return (
    <div

      className="confirmDiv d-flex flex-column justify-content-between  text-center"
    >
      <img className="header" src={Header} alt="" />
      <div className="mx-4">
        <p className="subHeading">
          We appreciate you taking part in this experience. You will receive a text<br /> message shortly providing
          instructions <br /> to receive your customized bag.
        </p>
        <p className="subHeading"> If you did not receive a text message, please try again by tapping below.</p>
        <Button className="px-5 btn-lg border-2 w-100 mt-5" onClick={() => navigate("/", { replace: true })}>
          START OVER
        </Button>
      </div>
      <img className="footer" src={footer} alt="" />
      <p className="copyRight" >@ 2024 Prudentails Financial, Inc. and its related entities. 1083739-00001-00</p>
    </div>
  );
}
