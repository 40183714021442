import "./userInfo.scss";
import Joi from "joi";
import { useState } from "react";
import toast from "react-hot-toast";
import client from "../../services/axios";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import Header from "./../../assets/images/header.png";
import { MAX_CHAR_LENGTH, PRIVACY_POLICY_URL } from "../../AppConstants/AppConstants";
import { CONFIRM_SCREEN, USER_INFO_API_PATH } from "../../AppConstants/AppConstants";
import { Button, Row, Form, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import Terms from "../TermsAndConditions";
import footer from "./../../assets/images/footer.png";
export const UserInfoContainer = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nuCaps, setNuCaps] = useState(1);
  const [opt_1, setOpt_1] = useState(false);
  const [opt_2, setOpt_2] = useState(false);
  const [initials, setInitials] = useState(Array.from(Array(nuCaps)).fill(""));
  const [pnError, setPnError] = useState("");
  const [opt_1Error, setOpt_1Error] = useState("");
  const [opt_2Error, setOpt_2Error] = useState("");

  const setInitialValue = (index, value) => {
    let new_initials = [...initials];
    new_initials[index] = value;
    setInitials([...new_initials]);
  };

  const handleSelect = (eventKey) => {
    setInitials(Array.from(Array(parseInt(eventKey))).fill(""));
    setNuCaps(parseInt(eventKey));
  };
  const [messages, showMessages] = useState(false);

  const validateForm = () => {
    const schema = Joi.object().keys({
      phoneNumber: Joi.string()
        .regex(/^(\+?\d{1,2}\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/)
        .message("Invalid phone number format. It should start with a plus sign and contain 7-15 digits."),
      nuCaps: Joi.number().required(),
      name: Joi.string().required(),
      lastName: Joi.string().required(),
      initials: Joi.array().min(Joi.ref("nuCaps")).items(Joi.string().min(1).required()).required(),
      opt_1: Joi.boolean().required(),
      opt_2: Joi.boolean().required(),
    });
    const { error } = schema.validate({ name, lastName, phoneNumber, initials, nuCaps, opt_1, opt_2 });
    if (!opt_1) {
      setOpt_1Error("Please agree terms & conditions!");
    }
    if (!opt_2) {
      setOpt_2Error("Please agree age consent!");
    }
    console.log({ initials, error });
    return error == undefined;
  };
  function isValidPhoneNumber(phoneNumber) {
    const phonePattern = /^(\+?\d{1,2}\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phonePattern.test(phoneNumber);
  }

  const handlePnChange = (e) => {
    setPhoneNumber(e);
    console.log({ e, status: isValidPhoneNumber(e) });
    if (!isValidPhoneNumber(e)) {
      setPnError("Not Valid Number");
    } else {
      setPnError("");
    }
  };


  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    showMessages(true);
    if (phoneNumber == "") setPnError("Mobile Number is required");
    if (!validateForm()) {
      return;
    }
    if (!opt_2 || !opt_1) {
      return
    }
    const toastId = toast.loading("Creating User Details");
    try {
      const payload = { name, lastName, email, phoneNumber, optIn1: opt_1, optIn2: opt_2, requestCapCount: nuCaps };

      Object.values(initials).map((value, i) => {
        Object.assign(payload, { [`initial${i + 1}`]: value });
      });
      console.log({ payload, initials });
      const response = await client.post(USER_INFO_API_PATH, payload);
      if (200 == response.status) {
        toast.success("User Details Saved Successfully !", { id: toastId });
        setTimeout(() => {
          toast.remove();
          navigate(CONFIRM_SCREEN);
        }, 1000);
      } else
        toast.error(response?.response?.data?.errorMessage ? "Invalid Number" : "Something went wrong!", {
          id: toastId,
        });
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong!", { id: toastId });
    }
  };
  const numberOptions = [1, 2, 3, 4];
  const [showTerms, setShowTerms] = useState(false);
  return (
    <div className="userInfoDiv">
      <img className="header" src={Header} alt="" />
      <div className="px-4 text-sm">
        <p className="homeSubHeading">To order your embroidered bag please complete the details below.</p>
        <p className="homeSubHeading">
          Once entered you will receive a text message from our embroidery team. You will need to show the embroidery
          team the text message when you hand them your bag.
        </p >
        <p className="homeSubHeading">We will send you a second text message when your bag is ready to collect.</p>
      </div>

      <Modal show={showTerms}>
        <div className="px-3 py-2 " style={{ backgroundColor: "#17150A" }}>
          <div className="d-flex flex-row justify-content-end">
            <i onClick={() => setShowTerms(!showTerms)} style={{ fontSize: "30px" }} className="bi bi-x"></i>
          </div>
          <Terms></Terms>
          <Button onClick={() => setShowTerms(!showTerms)} className="modelButton">OK</Button>
        </div>

      </Modal>
      <div className="m-4 p-2 rounded">
        <Form
          noValidate
          validated={messages}
          onSubmit={handleSubmit}
          className="d-flex flex-column align-items-center justify-content-center px-2 w-100"
        >
          <div className=" d-flex flex-column gap-3 w-100 ">
            <div>
              <p className="mt-4 m-1">
                How many bags do you have to embroider?
              </p>
              <Row>
                <Form.Group>
                  <DropdownButton title={`${nuCaps}`} onSelect={handleSelect}>
                    {numberOptions.map((number) => (
                      <Dropdown.Item className="dropDownItem" key={number} eventKey={number}>
                        {number}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </Row>

              <p className="homeSubHeading m-2 text-whitesmoke">
                Enter initials and/or numbers to embroider on your bags.
              </p>
              <p className="p-0 m-0 text-secondary" style={{ fontSize: "0.9rem", textAlign: "right" }}>
                * Max 2 Characters
              </p>
            </div>

            {initials.map((a, i) => (
              <Row key={i}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    maxLength={2}
                    required
                    minLength={1}
                    className="form-control placeholder-white bg-white  fw-semibold  px-3 py-3 text-black"
                    placeholder="Initial and/or number*"
                    isValid={/^.{3}$/.test(initials[i])}
                    value={initials[i]}
                    onChange={(evt) => {
                      if (/^[A-Za-z0-9 ]*$/.test(evt.target.value)) {
                        setInitialValue(i, `${evt.target.value}`.toLocaleUpperCase());
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Initials can not be empty
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            ))}
            <Row>
              <Form.Group>
                <Form.Control
                  id="formName"
                  type="text"
                  name="name"
                  required
                  maxLength={MAX_CHAR_LENGTH}
                  className="form-control placeholder-white bg-white  fw-semibold  px-3 py-3 text-black"
                  placeholder="First Name*"
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                />
                <Form.Control.Feedback type="invalid">First Name is Required</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group>
                <Form.Control
                  id="formName"
                  type="text"
                  name="lastName"
                  required
                  maxLength={MAX_CHAR_LENGTH}
                  className="form-control placeholder-white bg-white  fw-semibold  px-3 py-3 text-black"
                  placeholder="Last Name*"
                  value={lastName}
                  onChange={(evt) => setLastName(evt.target.value)}
                />
                <Form.Control.Feedback type="invalid">Last Name is Required</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group>
                <div
                  style={{ borderColor: messages && pnError !== "" ? "red" : "unset" }}
                  className="form-control placeholder-white bg-white  fw-semibold  px-3 py-3 text-black"
                >
                  <PhoneInput
                    limitMaxLength
                    defaultCountry="US"
                    addInternationalOption={false}
                    countries={JSON.parse(process.env.REACT_APP_COUNTRY_CODES) ?? ["US"]}
                    placeholder="Mobile Number*"
                    value={phoneNumber}
                    onChange={(e) => handlePnChange(e)}
                  />
                </div>
                {messages && pnError !== "" ? (
                  <div style={{ color: "red", fontSize: "0.875rem" }}>{pnError}</div>
                ) : null}
              </Form.Group>
            </Row>


            <p className="required">
              *Required
            </p>
            <Row className="CheckBoxWithLabel">
              <Form.Group>
                <Form.Check type="checkbox" id="opt-1">
                  <div className="d-flex flex-row">
                    <Form.Check.Input
                      size="lg"
                      type="checkbox"
                      value={opt_1}
                      onChange={(e) => {
                        if (e.target.checked) setShowTerms(true);
                        setOpt_1(e.target.checked);
                        if (!opt_1) {
                          setOpt_1Error("");
                        }
                      }}
                    />
                    <Form.Label className="CheckBoxlabel">
                      I agree to the <a className="anchorTags" onClick={() => setShowTerms(!showTerms)} >Terms and Conditions.</a>
                    </Form.Label>
                  </div>
                </Form.Check>
                {messages && opt_2Error !== "" ? (
                  <div style={{ color: "red", fontSize: "0.875rem" }}>{opt_1Error}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="CheckBoxWithLabel">
              <Form.Group>
                <Form.Check type="checkbox" id="opt-2">
                  <div className="d-flex flex-row">
                    <Form.Check.Input
                      size="lg"
                      type="checkbox"
                      value={opt_2}
                      onChange={(e) => {
                        setOpt_2(e.target.checked);
                        if (!opt_2) {
                          setOpt_2Error("");
                        }
                      }}
                    />
                    <Form.Label className="CheckBoxlabel">
                      I am at least 18 years old.
                    </Form.Label>
                  </div>
                </Form.Check>
                {messages && opt_2Error !== "" ? (
                  <div style={{ color: "red", fontSize: "0.875rem" }}>{opt_2Error}</div>
                ) : null}
              </Form.Group>
            </Row>
          </div>
          <Button
            type="submit"
            className="my-4 "
            // disabled={!validateForm()}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Form>
      </div>

      <br />
      <div className="bottomContent">
        <p className="homeSubHeading">
          For this event, your phone number will only be used
          to inform you that your bag is ready for pickup. Your
          phone number will not be used for marketing
          purposes.
        </p>
        <p className="homeSubHeading">
          *Standard Carrier rates for calls, text, SMS, and/or
          MMS may apply. Please check with your carrier about
          your rate plan for questions regarding your cellular plan
          charges.
        </p>
        <a id="anchorTag" href={PRIVACY_POLICY_URL} target="_blank"><p id="anchorTag">Prudential Privacy Center</p></a>
        <img className="footor" src={footer} alt="" />
      </div>
    </div>
  );
};
