import React from 'react';

export default function Terms() {
    return (
        <div className='termsModel'>
            <h5 className='termsHeading'>TERMS AND CONDITIONS FOR<br />
                CONTENT SUBMISSION, RELEASE AND MARKETING SOLICITATIONS</h5>
            <br />
            <p>The Service: Military Camo Bag customization experience known as Hat Customization Experience. The
                fan will participate in having their free bag they receive on ingress at the Prudential Center customized
                compliments of Prudential. This is an activation that takes place on the main concourse during Military
                Appreciation Night presented by Prudential. The user/fan can share their photo with their bag. (“User
                Content”).</p>
            <p>For good and valuable consideration, and in exchange for participating in and being filmed,
                photographed or recorded as part of the Service, I agree to these terms and condition (the
                “Agreement”) as follows:</p>
            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                I hereby grant to Prudential Financial, Inc. (“Prudential”) and , either of its agents and others working on Prudential’s
                behalf (collectively, the “Licensed Parties”), the irrevocable right and permission to use, distribute, display, publish, 
                exhibit, digitize, broadcast, reproduce, license, sublicense, transfer and otherwise exploit my name, image, recorded performance,
                voice, likeness, statements and actions (my “Image”), and any materials based or derived from these items (together with Image, the “Work”), 
                and to give others the right to make such use, in any manner that the Licensed Parties choose and in any media now known or hereinafter invented,
                throughout the world in perpetuity, for the purpose of advertising, promoting or publicizing Prudential’s products and services and for any other lawful purpose.
                </p>
            </section>
            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    I acknowledge that neither Prudential nor any Licensed Party is required to compensate or credit me or anyone else in connection with this
                    Agreement or any such use. Notwithstanding the foregoing, in the event the Work is used in such a manner as to be subject to the jurisdiction
                    of any SAG-AFTRA collective bargaining agreement, I agree to execute a standard scale contract and accept minimum scale compensation for such use.
                </p>
            </section>
            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    I agree that any and all rights in materials produced and/or resulting from this Agreement are and shall be owned exclusively by Prudential.
                    If for any reason any of the foregoing does not vest in Prudential, I hereby assign to Prudential all right, title and interest in and to the same.
                    I hereby waive my right to inspect or approve the Work, the User Content once I submit it to the Website or any materials produced hereunder.
                    Nothing in this Agreement requires the Licensed Parties or
                    its licensees to make any use of the Work, the Program or any materials produced hereunder, or the rights granted herein.
                </p>
            </section>

            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    I represent and warrant that (i) I have not given any third party the exclusive right to use my name, image, voice, likeness, or performance,
                    and I am free to sign this Agreement without conflict with any existing commitment on my part; and (ii) I am solely responsible for any government
                    employment tax, insurance and immigration obligations that may results or arise from the use of my appearance or the Work hereunder.

                </p>
            </section>

            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    I release the Licensed Parties, any company or person employed by or related to the Licensed Parties, and any person or company that obtains any
                    rights from the Licensed Parties described above, from all liabilities and claims, whether known or unknown, asserted or non-asserted, that relate
                    in any way to the rights I have given to the Licensed Parties above or my participation in the User Content including, but not limited to, those
                    arising from or related to editorial acts, copyright, defamation, misrepresentation, faulty reproductions, violation of right of publicity or invasion of privacy.
                </p>
            </section>

            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    Subject to the terms of this Agreement, Prudential grants me permission to make personal,  non-commercial use of the Work.  I agree to cease any use
                    of the Work immediately upon Prudential’s reasonable request and cooperate with Prudential’s reasonable request to remove the Work from all media.

                </p>
            </section>

            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    I shall not make or authorize any public statement concerning this Agreement without the prior consent written of the Licensed Parties in each instance.
                    I will regard and preserve in strict confidence all information related to the business, accounting, personnel, products and services of Licensed Parties,
                    its affiliates and third parties with whom they do business (“Confidential Information”) that is not otherwise publicly available, which may be obtained by me
                    from any source as a result of this Agreement. I will not, without first obtaining the Licensed Parties’ prior written consent, disclose to any person or use
                    for my own benefit or for the benefit of any third party any Confidential Information.

                </p>
            </section>


            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    By using the features of the Website and submitting User Content to the Website, I agree to abide by and be bound by this Agreement the Privacy Policy f
                    or the Website which is available at https://www.prudential.com/links/privacy-center. By submitting User Content to the Website, I agree: (a) that the Licensed
                    Parties will have no liability whatsoever for any injuries, losses, harm, damage, cost, or expense, including, without limitation, any personal injury
                    (including from COVID-19 or its variants), arising from or in connection with participation, directly or indirectly, in the Website or any Website-related activity;
                    (b) that I hereby waive all rights to claim any punitive, incidental, consequential, or  other damages,; (c) all causes of action arising out of or connected with
                    this Website shall be resolved individually, without resort to any form of class action; and (d) any and all claims, judgments, and award shall be limited to actual out-of-pocket
                    costs incurred, excluding attorneys’ fees and court costs.

                </p>
            </section>

            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    The invalidity or unenforceability of any provision of this Agreement shall not affect the validity or enforceability of any other provision. In the event
                    that any provision is determined to be invalid or otherwise unenforceable or illegal, this Agreement shall otherwise remain in effect and shall be construed
                    in accordance with their terms as if the invalid or illegal provision were not contained herein.

                </p>
            </section>

            <section className="d-flex flex-row">
                <p className="px-2">*</p>
                <p className="termsPoints">
                    This Agreement contains the entire understanding between the parties regarding the subject matter hereof, supersedes all prior understandings and may not be
                    modified except by a writing signed by both parties. This Agreement shall be construed in accordance with the laws of the State of New Jersey, without regard
                    to any conflict of laws.
                </p>
            </section>

            <p><b>I fully understand the contents of this Agreement and that the Licensed Parties are relying on the rights that I have granted to it, and I voluntarily agree to the
                terms and conditions of the Agreement.  I am eighteen (18) years of age or older any by my submission of the User Content and clicking ‘Agree and Finish” I am bound
                by the terms of the Agreement.</b></p>
        </div>
    );
}
